import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import { supabase } from '../libraries/supabase';
import { AuthenticationContext } from '../contexts/AuthContext';
import InfoModal from './InfoModal';
import AddSMTPSenderAddress from './AddSMTPSenderAddress'
import AddGmailWithAppPassword from './AddGmailWithAppPassword';
import { campaignExtractor } from '../utils/campaignExtractor';
import EmailingAPI from '../apis/EmailingAPI';
import '../styles/CampaignList.css'

const SenderEmailLists = ({ senderEmails, setUserData }) => {
  const { user, userSession } = useContext(AuthenticationContext);
  const [fetchError, setFetchError] = useState(null);
  const [deletableAddressId, setDeletableAddressId] = useState(null)
  const [isViewExpanded, setIsViewExpanded] = useState(false)
  const [visibleEmailCount, setVisibleEmailCount] = useState(3)
  const [modalConfig, setModalConfig] = useState({
    modalBody: "Deleting a sender email address will also delete ALL the campaigns, email threads and followups associated with it. This action is not reversible. Do you wish to proceed?",
    modalHeader: "Warning!",
    headerStyles: {
      backgroundColor: '#dc3545',
      color: 'white'
    },
    buttonMessage: "Delete",
    buttonStyles: "btn btn-danger",
    addressId: null,
    modalVisible: false
  });
  const addAddressConfig = {
    editAddress: false,
    buttonText: "SMTP",
    inputType: "button",
    buttonStyle: "btn btn-primary",
    buttonCss: {
      width: "30%"
    }
  }

  const editAddressConfig = {
    addressToEdit: null,
    editAddress: true,
    inputType: "a",
    buttonText: "Edit Address",
    buttonStyle: "dropdown-item",


  }

  const navigate = useNavigate();

  const handleCreateCampaign = (emailAddress) => {
    navigate('/create-campaign', { state: { emailAddress } });
  }

  const handleDeleteAddress = async (row) => {
    try {
      const sendableObject = {
        userSession: userSession, // Key-value pair
        address_id: row.address_id, // Key-value pair
      };
       await EmailingAPI.post("delete-sender-address/", sendableObject)
      
      const response = await EmailingAPI.post("get-user-campaigns/", sendableObject)
      if (response.status === 200) {
        setUserData({
          senderEmailAddresses: response.data,
          draftCampaigns: campaignExtractor(response.data, 'draft_campaigns'),
          sentCampaigns: campaignExtractor(response.data, 'sent_campaigns'),
          scheduledCampaigns: campaignExtractor(response.data, 'scheduled_campaigns')
        })
      }
      setFetchError(null)
      setModalConfig({
        modalBody: "",
        modalHeader: "",
        headerStyles: {
          backgroundColor: '#dc3545',
          color: 'white'
        },
        buttonMessage: "Delete",
        buttonStyles: "btn btn-danger",
        addressId: null,
        modalVisible: false
      }) 


    } catch (error) {
      console.error('Error in handleDeleteAddress:', error);
      setFetchError("An unexpected error occurred");
    }
  }

  const handleDelBtnClick = (row) => {
    setModalConfig(prevConfig => ({
      ...prevConfig,
      modalVisible: true
    }));
    setDeletableAddressId(row)
  }

  const handleViewMoreAddresses = (viewCount) => {
    if (isViewExpanded === false) {
      setIsViewExpanded(true)
      setVisibleEmailCount(viewCount)
      return
    }
    if (isViewExpanded === true) {
      setIsViewExpanded(false)
      setVisibleEmailCount(3)
      return
    }

  }

  const generateEmailCards = (senderEmails, cardsToDisplay) => {
    const emailCards = [];

    for (let index = 0; index < (senderEmails.length < cardsToDisplay ? senderEmails.length : cardsToDisplay); index++) {
      const row = senderEmails[index];
      emailCards.push(
        <div className="col-12 col-sm-6 col-md-4 col-xl-4 col-xxl-3 mb-4" key={index + 1}>
          <div className="card" style={{ width: "100%" }}>
            <div className="card-body">
              <h5 className="card-title text-start" style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}>{row.email_address}</h5>
              <h6 className="card-subtitle mb-3 mt-2 text-muted"><strong>{row.scheduled_campaigns.length + row.sent_campaigns.length + row.draft_campaigns.length} Campaigns</strong></h6>
              <hr className="border-1 border-top border-dark" />
              <div className="d-flex justify-content-center align-items-center" style={{ textAlign: "center", fontSize: "0.85em", gap: "0.5em" }}>

                <button
                  className="address-list-button"
                  onClick={() => handleCreateCampaign(row)}
                >
                  <i className="bi bi-file-earmark-plus"></i>
                  Create Campaign
                </button>

                <div className="dropdown ms-auto">
                  <button className="btn btn-light" 
                  style={{ background: "transparent", border: "none", padding: 0 }} 
                  type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-three-dots-vertical"></i>
                  </button>
                  <div className="dropdown-menu">
                    <li>
                    <AddSMTPSenderAddress
                            configuration={{
                              ...editAddressConfig,
                              addressToEdit: row.email_address || null,
                            }}
                          />
                    </li>
                    <li><button onClick={() => handleDelBtnClick(row)} className="dropdown-item" href="#">Delete Address</button></li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <>{emailCards}</>;
  };
  return (
    <div className='container'>
      <>
        <div className="container mt-4">
          <div className="row">
            {/* Static card */}
            <div className="col-12 col-sm-6 col-md-4 col-xl-4 col-xxl-3 mb-4">
              <div className="card" style={{ width: "100%" }}>
                <div className="card-body">
                  <h5 className="card-title text-center">Add Email Address</h5>
                  <div className="d-flex justify-content-center" style={{ gap: "0.5em" }}>
                    <AddSMTPSenderAddress configuration={addAddressConfig} />
                    <AddGmailWithAppPassword   configuration={{...addAddressConfig, buttonText: "Gmail",}} />
                  </div>
                  <div className="d-flex justify-content-center" style={{ textAlign: "center", fontSize: "0.85em", gap: "0.5em" }}>
                    <p style={{ width: "30%" }}>Custom Domains</p>
                    <p style={{ width: "30%" }}>With App Password</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Dynamic cards */}
            {generateEmailCards(senderEmails, visibleEmailCount)}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button className='btn btn-link' 
          style={{fontSize: "1em"}} 
          onClick={() => handleViewMoreAddresses(senderEmails.length)}>
            {isViewExpanded ? "View Less" : "View More"}</button>
        </div>
      </>
      {modalConfig.modalVisible &&
        <InfoModal
          setModalConfig={setModalConfig}
          modalConfig={modalConfig}
          parentSpecificFunction={() => handleDeleteAddress(deletableAddressId)}
          errorMessage={fetchError}
          setErrorMessage={setFetchError}
        />}
    </div>
  );
}

export default SenderEmailLists
