import React, { useState, useRef } from 'react';
import '../styles/ModalStyles.css'
import InlineError from '../errors/InlineError';
import RichTextEditor from '../components/RichTextEditor';
import FormSwitch from '../components/FormSwitch';
import BasicModal from '../components/BasicModal';

const EditEmail = ({ writeTextInAllRows, initialEmailContent, stylingProp }) => {

  const emailBodyTextEditorRef = useRef(null);
  const subjectLineTextEditorRef = useRef(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [inlineError, setInlineError] = useState(null);
  const [emailBody, setEmailBody] = useState("");
  const [emailSubjectLine, setEmailSubjectLine] = useState("")
  const [isTextEditorPlain, setTextEditorPlain] = useState(false);

  const generateInitialContent = (editorRef, providedValue) => {
    if (editorRef.current !== null && typeof editorRef.current.getHTML() === "string") {
      return editorRef.current.getHTML()
    } else {
      return providedValue
    }
  }

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const handleTextChange = (text, columnKey, errorMethod) => {
    //console.log(text);
    
    writeTextInAllRows(text, columnKey, errorMethod)
  }

  return (
    <div>
      <button
        title="Edit the email subject line and body."
        className={stylingProp.buttonStyling}
        onClick={() => toggleModal()} > <i className="bi bi-pencil-square"></i>
        {stylingProp.buttonText}</button>

      {modalVisible ?
        <>
        <BasicModal title={"Edit Email Template"}>
        <div className="modal-body">
                  <div className="edit-email-form-group">
                    <label /* htmlFor="email-subject-line" */ className="edit-email-form-label">Email Subject Line</label>
                    <textarea
                        id="email-body"
                        value={emailSubjectLine}
                        onChange={(e) => setEmailSubjectLine(e.target.value)}
                        className="edit-email-form-textarea"
                    />
{/*                     <RichTextEditor
                      textEditorRef={subjectLineTextEditorRef}
                      editorConfiguration={{
                        showMenuBar: false,
                        initialContent: generateInitialContent(subjectLineTextEditorRef, initialEmailContent.subjectLine),
                        cssStyling: "bulk-email-editor"
                      }}
                    /> */}
                  </div>

                  <div className="edit-email-form-group">

                    <FormSwitch value={isTextEditorPlain} setValue={setTextEditorPlain} displayedTexts={{firstText: "Rich Text", secondText: "Plain Text" }} />
                    {/* <label htmlFor="email-body" className="edit-email-form-label">Email Body</label> */}
                    {isTextEditorPlain ?
                      <RichTextEditor
                        textEditorRef={emailBodyTextEditorRef}
                        editorConfiguration={{
                          showMenuBar: true,
                          initialContent: generateInitialContent(emailBodyTextEditorRef, initialEmailContent.emailBody),
                          cssStyling: "bulk-email-editor"
                        }}
                      />
                      :
                      <textarea
                        id="email-body"
                        value={emailBody}
                        onChange={(e) => setEmailBody(e.target.value)}
                        className="edit-email-form-textarea"
                      />
                    }

                  </div>

                  <div>
                    <p className='modal-capsule-text'><i className="bi bi-exclamation-circle-fill"></i> {"Write {column header} to apply the cell content in the email subject and body."}</p>
                    <p className='modal-capsule-text'><i className="bi bi-exclamation-circle-fill"></i>{"Example: Hello {first name}, I love your {user site}. "}</p>
                    <p className='modal-capsule-text'><i className="bi bi-exclamation-circle-fill"></i> {"Plain text emails generally have better deliverability than rich text ones."}</p>
                  </div>

                  {inlineError && <InlineError errorMessage={inlineError} setErrorMessage={setInlineError} />}
                </div>
                <div className="modal-footer">
                  <button type="button" onClick={() => handleTextChange(emailSubjectLine, "Subject Line", setInlineError)} className="btn btn-primary">Set Subject Line</button>
                  
                  {isTextEditorPlain ? 
                  <button type="button" onClick={() => handleTextChange(emailBodyTextEditorRef.current.getHTML(), "Email Body", setInlineError)} className="btn btn-primary">Set Email Body</button>
                  :                   
                  <button type="button" onClick={() => handleTextChange(emailBody, "Email Body", setInlineError)} className="btn btn-primary">Set Email Body</button>
                }
                  <button type="button" className="btn btn-secondary" onClick={toggleModal}>Close</button>
                </div>
        </BasicModal>
        </>
        :
        null
      }
    </div>
  )
}

export default EditEmail
