export const validateFollowUpSchedules = (campaignConfiguration) => {
    
    let launchDate = new Date()
    if (campaignConfiguration.emailSendTime) {
      launchDate = new Date(campaignConfiguration.emailSendTime.ISODate)
    }
    const followUps = [
      { key: 'Follow-Up 1 Schedule', date: campaignConfiguration['Follow-Up 1 Schedule']?.ISODate },
      { key: 'Follow-Up 2 Schedule', date: campaignConfiguration['Follow-Up 2 Schedule']?.ISODate },
      { key: 'Follow-Up 3 Schedule', date: campaignConfiguration['Follow-Up 3 Schedule']?.ISODate },
      { key: 'Follow-Up 4 Schedule', date: campaignConfiguration['Follow-Up 4 Schedule']?.ISODate }
    ];

    const dateObjects = followUps
      .map(followup => ({ key: followup.key, date: followup.date ? new Date(followup.date) : null }))
      .filter(followup => followup.date !== null);

    for (let i = 0; i < dateObjects.length; i++) {
      // Check if any follow-up date is earlier than the launch date
      if (dateObjects[i].date <= launchDate) {
        return `${dateObjects[i].key} should be later than the campaign launch date.`;
      }

      // Check if follow-up dates are in correct order
      if (i > 0 && dateObjects[i - 1].date >= dateObjects[i].date) {
        return `${dateObjects[i].key} should be later than ${dateObjects[i - 1].key}.`;
      }
    }

    return true
  };