import React from 'react'
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useNavigate } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { supabase } from '../libraries/supabase';
import { AuthenticationContext } from '../contexts/AuthContext';

const LoginPage = () => {

  const {user} = useContext(AuthenticationContext);
  const navigate = useNavigate();

  useEffect(() => {

    if(user){
      navigate("/dashboard")
    }

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event) => {
      if (event === "SIGNED_IN") {
        navigate("/dashboard");
      } else  {
        navigate("/");
      }
    });
  
    return () => {
      //console.log(authListener);
      if (authListener) {
        authListener.subscription.unsubscribe();
      }
    };
  }, [user, navigate]);


  
  return (
    <div className='container'>
      <header className='App-header'>
      <Auth
        supabaseClient={supabase}
        appearance={{theme: ThemeSupa}}
        theme='dark'
        providers={[ "google"]}
      />
      </header>
    </div>
  )
}

export default LoginPage

