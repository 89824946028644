import React from 'react'
import { useState, useRef, useContext } from 'react';
import EmailingAPI from '../apis/EmailingAPI';
import { AuthenticationContext } from './../contexts/AuthContext';
import LoadingIcon from '../components/LoadingIcon';
import planer from 'planer';
import InlineError from '../errors/InlineError';
import ErrorModal from '../errors/ErrorModal';
import RichTextEditor from '../components/RichTextEditor';
import FormSwitch from '../components/FormSwitch';
import BasicModal from '../components/BasicModal';
import DOMPurify from 'dompurify';

const EmailConversationModal = ({  row,  senderAddress }) => {
  const { userSession } = useContext(AuthenticationContext);
  const [modalVisible, setModalVisible] = useState(false);
  const emailBodyTextEditorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [inlineError, setInlineError] = useState(null);
  const [modalError, setModalError] = useState(null)
  const [emailThread, setEmailThread] = useState(null)
  const [cleanedText, setCleanedText] = useState(true)
  const [replyButtonText, setReplyButtonText] = useState("Send Reply")


  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  

  const retrieveThread = async () => {
    let threadInfo = row.original
    let threadDetails = {
      userSession,
      threadInfo,
      senderAddress
    }
    let result = {
      status: null,
      data: null
    }

    try {
      const response = await EmailingAPI.post('retrieve-email-thread/', threadDetails)

      if (response.status === 200) {

        const addTypeOfMailboxProperty = (data) => {
          for (const inbox in data) {
            if (inbox === 'receivedEmails' && data[inbox].length > 0) {
              data[inbox].forEach(element => {
                element.mailbox_type = 'received'
              });
            }
            if (inbox === 'sentEmails' && data[inbox].length > 0) {
              data[inbox].forEach(element => {
                element.mailbox_type = 'sent'
              });
            }
          }
          return data
        }
        const { receivedEmails, sentEmails } = addTypeOfMailboxProperty(response.data.result)
        const emailThread = [...receivedEmails, ...sentEmails]
        emailThread.sort((a, b) => new Date(a.date) - new Date(b.date))

        for (let i = 0; i < emailThread.length; i++) {
          emailThread[i].processed_text = planer.extractFrom(emailThread[i].text, 'text/plain');
        }
        return result = {
          status: 200,
          data: emailThread
        }
      }
    } catch (error) {
      result = {
        status: 500,
        data: "Could not retrieve the conversation."
      }
      return result
    }
  }

  const handleSendReply = async (row) => {
    setReplyButtonText("Sending...")

    let emailToSendObject = {
      replyText: emailBodyTextEditorRef.current.getHTML(),
      recipient: row.original.Recipient,
      inReplyTo: row.original['Conversation Details'].in_reply_to,
      initMsgId: row.original.initMsgId || null,
      references: row.original['Conversation Details'].references_str,
      subject: emailThread[0].subject || null,
      senderAddress,
    }


    if (!emailToSendObject.replyText) {
      setInlineError("Cannot send an empty reply!")
      return
    }
    if (!emailToSendObject.initMsgId) {
      setInlineError("Cannot succesfully recover the initial message in the thread.")
      return
    }
    if (!emailToSendObject.references) {
      setInlineError("Cannot succesfully compose the email's references.")
      return
    }
    if (!emailToSendObject.subject) {
      setInlineError("Cannot succesfully recover the thread subject line")
      return
    }
    const replyData = {
      userSession,
      emailToSendObject
    }

    try {
      const replyResponse = await EmailingAPI.post("send-individual-reply/", replyData);
      if (replyResponse) {
        setInlineError(null)

        if (replyResponse.status === 200) {
          let refreshedThread = await retrieveThread()
          if (refreshedThread.status === 200) {
            setEmailThread(refreshedThread.data)
          }

          setReplyButtonText("Reply Sent!")
        }
        if (replyResponse.status !== 200) {
          setReplyButtonText("Error!")
          setInlineError(replyResponse.data.message)
        }
      }
    } catch (error) {
      setInlineError("There was an error sending your email.")
    }
    setTimeout(() => setReplyButtonText("Send Reply"), 5000); // After 3 seconds
  }

  const calculateCardWidth = (textLength, minWidth, maxWidth) => {
    return Math.min(maxWidth, minWidth + textLength * 0.5);
  }

  const handleModalButtonClick = async (e) => {
    setLoading(true)
    let thread = await retrieveThread()
    if (thread.status !== 200) {
      setLoading(false)
      setModalError("Could not retrieve the conversation.")
    }
    if (thread.status === 200) {
      setEmailThread(thread.data)
      setLoading(false);
      setModalVisible(true)

    }
  }
  return (
    <div>
      <div className='conversation-modal-btn-div'>
        <button
          className='address-list-button'
          style={{
            fontSize: '1em',
            maxWidth: '100%',
            textOverflow: 'ellipsis'
          }}
          onClick={(e) => handleModalButtonClick(e)}
        >
          <i className="bi bi-envelope-paper"></i>
          View Conversation
        </button>
      </div>
      {loading ? <LoadingIcon /> : null}
      {modalError && (<ErrorModal errorMessage={modalError} setErrorMessage={setModalError} />)}
      {modalVisible && !loading ?
        <>
          <BasicModal title={"Subject line: " + emailThread[0].subject || "MISSING: Subject line is missing or could not be retrieved"}>
            <div className="modal-body">

              <FormSwitch value={cleanedText} setValue={setCleanedText} displayedTexts={{ firstText: "Original", secondText: "Cleaned" }} />

              {emailThread.map((item, index) => (

                <div
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: item.mailbox_type === 'received' ? 'flex-start' : 'flex-end',
                    marginBottom: '10px',
                  }}
                >
                  <div
                    style={{
                      maxWidth: cleanedText
                        ? `${calculateCardWidth(item.text.length, 1, 20)}em`
                        : `${calculateCardWidth(item.processed_text.length, 1, 20)}em`,
                    }}
                  >
                    {cleanedText ? (
                      <div 
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text) }}
                        className="email-content-bubble"
                        style={{
                          display: "block",
                          backgroundColor: item.mailbox_type === 'received' ? '#d1e7dd' : '#eef2fa'
                        }}></div>
                    ) : (
                      <div 
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.processed_text)}}
                        className="email-content-bubble"
                        style={{
                          display: "block",
                          backgroundColor: item.mailbox_type === 'received' ? '#d1e7dd' : '#eef2fa',

                        }}
                      ></div>
                    )}
                  </div>
                </div>
              ))}

              <div>
                <hr />
                <RichTextEditor
                  textEditorRef={emailBodyTextEditorRef}
                  editorConfiguration={{
                    showMenuBar: true,
                    initialContent: "Write your reply...",
                    cssStyling: "bulk-email-editor"
                  }}
                />
              </div>
            </div>
            {inlineError && (
              <InlineError errorMessage={inlineError} setErrorMessage={setInlineError} />
            )}

            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={() => handleSendReply(row)}>{replyButtonText}</button>
              <button type="button" className="btn btn-secondary" onClick={() => toggleModal()}>Close</button>
            </div>
          </BasicModal>
        </>
        :
        null}

    </div>
  )
}

export default EmailConversationModal
