import React from 'react'
import { useEffect, useState, useContext } from 'react';
import { AuthenticationContext } from '../contexts/AuthContext';
import EmailingAPI from '../apis/EmailingAPI';
import ErrorModal from '../errors/ErrorModal';
import LoadingIcon from '../components/LoadingIcon';
import UserDetails from '../components/UserDetails';
import StripePayments from "../components/StripePayments"

const ProfilePage = () => {
  const [loading, setLoading] = useState(true)
  const { user, userSession } = useContext(AuthenticationContext);
  const [fetchError, setFetchError] = useState(null)
  const [userData, setUserData] = useState(null)

  const emailToSendObject = {}
  const sendableObject = {
    emailToSendObject,
    userSession
  }
  useEffect(() => {
    async function getUserAccountOverview(userInformation) {
      try {
        const response = await EmailingAPI.post('get-user-account-overview/', userInformation); 
        if (response.status === 200) {
          setLoading(false)
          setFetchError(null)
          setUserData(response.data)
        }
      } catch (error) {
        setLoading(false)
        setFetchError("There was an error retrieving your user information.")
      }

    }
    getUserAccountOverview(sendableObject)


  }, []);

  return (
    <>
    {!loading && userData ? 
        <div>
        <UserDetails userInfo={userData} />
        <StripePayments userAccountData={userData} currentSubscription={userData.currSubscriptionPlanPriceId}/>
  
      </div>
      : null}

    {fetchError ?
        <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError} />
        :
        null
      }
    {loading ? <LoadingIcon/> : null}
    </>
  )
}

export default ProfilePage
