import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { supabase } from '../libraries/supabase';
import EmailingAPI from '../apis/EmailingAPI';
import { AuthenticationContext } from './../contexts/AuthContext';
import ErrorModal from '../errors/ErrorModal';
import LoadingIcon from '../components/LoadingIcon';
import { readableDateFormat } from '../utils/readableDateFormat';


const CheckCampaignReplies = ({table, campaignConfiguration}) => {
    const {user, userSession} = useContext(AuthenticationContext);
    const [loading, setLoading] = useState(false)
    const [fetchError, setFetchError] = useState(null);
    
    const handleReplyCheck = async () => {
        let emailToSendObject = {
            campaignConfiguration: campaignConfiguration,
        }
        let campaignData = {
            userSession,
            emailToSendObject
        }
        try {
            setLoading(true)
            const response = await EmailingAPI.post(
              'manual-campaign-reply-check', 
              campaignData,
              {
                timeout: 10000 
              }
            );
            
            if (response?.data?.message?.threadsWithReplies.length > 0 && response?.data?.message?.updatedCampaigns.length > 0) {
                
                let threadsWithReplies = response.data.message.threadsWithReplies;
                let campaignDetails = response.data.message.updatedCampaigns[0];
                
                let tableData = table.getCoreRowModel().rows;
                let tableMap = new Map();

                for (let i = 0; i < tableData.length; i++) {
                  let threadInitMsgId = tableData[i].original.initMsgId;
                  tableMap.set(threadInitMsgId, tableData[i].index)
                }

                if (threadsWithReplies.length > 0) {
                  threadsWithReplies.forEach(thread => {
                    let rowIndex = tableMap.get(thread.init_msg_id);

                    table.options.meta?.updateData(
                    rowIndex,
                    "Last Reply",
                    thread?.last_reply?.text || ""
                  ); 
  
                    table.options.meta?.updateData(
                      rowIndex,
                      "Last Reply Date",
                      readableDateFormat(thread.last_reply_date) || ""
                    ); 
  
                    table.options.meta?.updateData(
                      rowIndex,
                      "Follow-Up 1",
                      ""
                    );
  
                    table.options.meta?.updateData(
                      rowIndex,
                      "Follow-Up 2",
                      ""
                    );
  
                    table.options.meta?.updateData(
                      rowIndex,
                      "Follow-Up 3",
                      ""
                    );
                    }
                  );
                }
                    const updateCampaign = async () => {
                      const { error} = await supabase
                      .from('sent_campaigns')
                      .update({ unread_replies: 0 })
                      .eq('campaign_id', campaignConfiguration.campaignId)
                    }
                
                    updateCampaign()
                
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            
            setFetchError("Could not load thread replies")
        }
    }
  return (
    <div>
      <button 
      onClick={()=> handleReplyCheck()}
      className="table-bar-button">
      <i className="bi bi-envelope-paper"></i>
        Check Replies
        </button>
      {fetchError ? 
        <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError}/>
        :
        null
      }
      {loading ? <LoadingIcon/> : null}
    </div>
  )
}

export default CheckCampaignReplies
