import React from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Analytics = () => {
    const location = useLocation();
  
    useEffect(() => {
      // Initialize Google Analytics (replace G-XXXXXXXXXX with your Measurement ID)
      ReactGA.initialize('G-CTX767MK42');
    }, []);
  
    useEffect(() => {
      // Send a pageview to Google Analytics whenever the route changes
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }, [location]);
  
    return null; // This component does not render anything
  };
  
  export default Analytics;
