import React, { useState, useEffect, useContext } from 'react';
import ErrorModal from '../errors/ErrorModal'
import Papa from 'papaparse';


const TableDataUpload = ({ onTableDataReceived }) => {

  const [selectedFile, setSelectedFile] = useState(null);
  const [pasteContent, setPasteContent] = useState('');


  const [userUploadedData, setUserUploadedData] = useState(false);
  const [userChoseCsvUpload, setUserChoseCsvUpload] = useState(false);
  const [userChoseManualUpload, setUserChoseManualUpload] = useState(false);
  const [formError, setFormError] = useState(null);

  const resetComponent = () => {
    window.location.reload();
  };

  const handleFileChange = (event) => {
    let userFile = event.target.files[0]
    if (userFile && (userFile.type === 'text/csv' || userFile.name.endsWith('.csv'))) {
      setSelectedFile(userFile);
    }
    else {
      setFormError("Please upload a valid CSV file.")
      setUserChoseCsvUpload(false)
    }
  };

  const handleCsvClick = (e) => {
    setUserChoseCsvUpload(true);
    setUserChoseManualUpload(false);
  }

  const handleManualDataUploadClick = (e) => {
    setUserChoseManualUpload(true);
    setUserChoseCsvUpload(false);
  }

  const checkEmptyHeader = (value) => {    
    //TODO: Complete this with however many reserved headers you need
    return (

      value === undefined || value === null ||

      (typeof value === 'string' && value.trim().length === 0) ||

      (Array.isArray(value) && value.length === 0) ||

      (typeof value === 'object' && value !== null && Object.keys(value).length === 0) 
      
       || value === "Email Body" || value === "Subject Line" || value === "Preview Mail"

       || value === "Preview Mail" 
    );
  }



  const handlePaste = () => {
    const results = {
      data: [],
      errors: [],
      meta: {
        fields: ['email']
      }
    };
    const lines = pasteContent.split(/\r?\n/);
    
    const emailRegex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    
    lines.forEach(line => {
      const match = line.match(emailRegex);
      if (match) {
        results.data.push({
          email: match[0]
        });
      }
      else {
        results.errors.push({
          errors: line
        })
      }
    });
    
    if (results.data && results.data.length > 0) {
      onTableDataReceived(results);
      setUserUploadedData(true);
      setPasteContent('')
    } else {
      setFormError("Your uploaded data doesn't seem to contain any valid emails")
    }
  };


  const handleFileUpload = () => {

    if (selectedFile) {
      Papa.parse(selectedFile, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.meta.fields.some(checkEmptyHeader)) {
            setFormError("CSV file contains either: 1) columns without headers or 2) columns that use reserved keywords such as 'Subject Line' or 'Email Body'")
            return
          }
          
          onTableDataReceived(results);
          setSelectedFile(null);
          setUserUploadedData(true);
        },
        error: (error) => {
          console.error('Error parsing CSV file:', error);
        }
      });
    }
  };

  return (
    <div>
      <div className='d-flex justify-content-center'>
        <div>
          <h4
            className='mt-4 mb-4'
            style={{ textAlign: 'center' }}> <i className="bi bi-envelope-arrow-up-fill"></i> Upload Email Contacts</h4>
          <div>

            <button className='btn btn-primary me-2'
              onClick={() => handleCsvClick()} >
              <i className="bi bi-filetype-csv me-2" style={{ fontSize: "18px" }}></i>
              Upload .csv file</button>

            <button className='btn btn-primary me-2'
              onClick={() => handleManualDataUploadClick()} >
              <i className="bi bi-file-text me-2"></i>
              Manual entry</button>
            <button className='btn btn-primary me-2' onClick={() => resetComponent()}>Reset Campaign</button>
          </div>
          {userChoseCsvUpload && !userUploadedData ?
            <div className='mt-4'>

              <div className="input-group">
                <input type="file" className="form-control" onChange={handleFileChange} id="csvFileInput" aria-describedby="csvFileInput" aria-label="Upload" />
                <button
                  onClick={() => handleFileUpload()}
                  className="btn btn-outline-secondary"
                  type="button"
                  id="csvFileInput"
                  disabled={!selectedFile}
                >Upload</button>
              </div>

            </div>
            : null
          }




          {userChoseManualUpload && !userUploadedData ? (
            <div className='d-flex flex-column align-items-center mt-4'>
              <textarea
                placeholder="Paste or write emails here..."
                rows="10"
                cols="50"
                value={pasteContent}
                onChange={(e) => setPasteContent(e.target.value)}
              ></textarea>
              <button
                className="btn btn-primary mt-3"
                onClick={handlePaste}
              >
                Process Emails
              </button>
            </div>
          ) : null

          }
        </div>

      </div>
      {formError && <ErrorModal errorMessage={formError} setErrorMessage={setFormError}/>}
    </div>
  )
}

export default TableDataUpload
