import React from 'react'
import { readableDateFormat } from '../utils/readableDateFormat';



const UserDetails = ({ userInfo }) => {
  
  return (
    <div className="container mt-3">
      {/* Main Card Container */}
      <div className="card p-3 shadow-sm border-1">
  
        {/* Header Section */}
        <div className="mb-3 text-center">
          <h3 className="d-flex align-items-center justify-content-center">
            <i className="bi bi-person-circle me-2 fs-2"></i> 
            <span>{userInfo.accountEmail}</span>
          </h3>
          <hr className="my-3" />
        </div>
  
        {/* Usage & Limits Section */}
        <div className="mb- text-center">
          <h4 className="mb-4 d-flex justify-content-center align-items-center">
            <i className="bi bi-bar-chart me-2"></i> Usage & Limits
          </h4>
          <div className="row justify-content-center">
            <div className="col-md-2 mb-3">
              <label className="text-muted small">Monthly Sends</label>
              <h5>{userInfo.totalSendsPerPlan}</h5>
            </div>
            <div className="col-md-2 mb-3">
              <label className="text-muted small">Remaining Sends</label>
              <h5>{userInfo.emailSendsRemaining}</h5>
            </div>
            <div className="col-md-2 mb-3">
              <label className="text-muted small">Maximum Sender Addresses</label>
              <h5>{userInfo.maxSenderAddresses}</h5>
            </div>
            <div className="col-md-2 mb-3">
              <label className="text-muted small">Used Sender Addresses</label>
              <h5>{userInfo.senderAddresses}</h5>
            </div>
          </div>
        </div>
  
        {/* Billing Section */}
        <div className="mb-2 mt-2 text-center">
          <h4 className="mb-4 d-flex justify-content-center align-items-center">
            <i className="bi bi-cash-coin me-2"></i> Billing
          </h4>
          <div className="row justify-content-center">
            <div className="col-md-3 mb-3">
              <label className="text-muted small">Subscription Plan</label>
              <h5>{userInfo.subscriptionPlan}</h5>
            </div>
            <div className="col-md-3 mb-3">
              <label className="text-muted small">Subscription Status</label>
              <h5>{userInfo.subscriptionStatus}</h5>
            </div>
            <div className="col-md-3 mb-3">
              <label className="text-muted small">Subscription End Date</label>
              <h5>{userInfo.subscriptionEndDate !== null ? readableDateFormat(userInfo.subscriptionEndDate) : "Never"}</h5>
            </div>
          </div>
        </div>
  
        {/* Manage Subscription Section */}
        {userInfo.managementUrls === null ? null :         
        <div className="mb-4 text-center">
          <h4 className="mb-3 d-flex justify-content-center align-items-center">
          <i className="bi bi-credit-card me-2"></i> Manage Subscription
          </h4>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary px-4"
              onClick={() => window.location.href = userInfo.managementUrls}
            >
              Update
            </button>
          </div>
        </div>}

      </div>
    </div>
  );
}

export default UserDetails
