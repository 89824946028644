import React, { useCallback } from 'react'
import '../styles/TextEditorStyling.css'

const TextEditorMenuBar = ({ editor }) => {


  //TODO: Implement the font family / font size feature
  //TODO: Test this link extension
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  if (!editor) {
    return null
  }

  let menuBarStylingObject = {
    parentDivStyling: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '0.2em',
      alignItems: 'center'
    },
    buttonDivStyling: {
      paddingBottom: "0.1em",
      paddingTop: "0.1em",
      paddingLeft: "0.3em",
      paddingRight: "0.3em",
      borderRadius: "5px",
      fontSize: "1.1em"
    },
    selectDropdownStyling: {
    padding: '0.1em',
    borderRadius: '5px',
    fontSize: '1em',
    border: '1px solid #ccc',
    backgroundColor: 'white',
    color: '#333',
    outline: 'none', // Remove outline
    cursor: 'pointer'
    }

  }

  //const fontSizes = ['10px', '12px', '14px', '16px', '18px', '20px', '24px'];
  const fontSizes = ['0.6', '0.7','0.8', '0.9', '1.1', '1.2', '1.3', '1.4', '1.5'];
  return (
    <div style={menuBarStylingObject.parentDivStyling}>

      <div
        onClick={() => editor.chain().focus().undo().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .undo()
            .run()
        }
        className='button-div'
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-arrow-return-left"></i>
      </div>

      <div
        onClick={() => editor.chain().focus().redo().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .redo()
            .run()
        }
        className='button-div'
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-arrow-return-right"></i>
      </div>

      <div className="vr"></div>



      <div>
        <select
          onChange={(e) => editor.chain().focus().setFontFamily(e.target.value).run()}
          style={menuBarStylingObject.selectDropdownStyling}
          defaultValue={''}
        >
          {/* TODO: THIS IS BUGGED hook.js:608 Warning: Use the `defaultValue` or `value` props on <select> instead of setting `selected` on <option>. Error Component Stack */}
          <option key={'systems-font'} value={''} >
            System Font
          </option>
          <option key={'sans-serif'} value={'sans-serif'}>
            Sans Serif
          </option>
          <option key={'serif'} value={'serif'}>
            Serif
          </option>
          <option key={'monospace'} value={'monospace'}>
            Monospace
          </option>
          <option key={'cursive'} value={'cursive'}>
            Cursive
          </option>
          <option key={'fantasy'} value={'fantasy'}>
            Fantasy
          </option>
        </select>
      </div>

      <select
        onChange={(e) => editor.chain().focus().setFontSize(`${e.target.value}em`).run()}
        style={menuBarStylingObject.selectDropdownStyling}
        defaultValue={'1'}
      >
          {/* TODO: THIS IS BUGGED hook.js:608 Warning: Use the `defaultValue` or `value` props on <select> instead of setting `selected` on <option>. Error Component Stack */}
        <option key={'1'} value={'1'}>1</option>
        {fontSizes.map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>

      <div className="vr"></div>

      <div
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        className={editor.isActive('bold') ? 'button-div-active' : 'button-div'}
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-type-bold "></i>
      </div>

      <div
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        className={editor.isActive('italic') ? 'button-div-active' : 'button-div'}
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-type-italic"></i>
      </div>


      <div
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        className={editor.isActive('underline') ? 'button-div-active' : 'button-div'}
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-type-underline"></i>
      </div>

      <div
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        className={editor.isActive('strike') ? 'button-div-active' : 'button-div'}
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-type-strikethrough"></i>
      </div>

      <div className="vr"></div>


      <div
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'button-div-active' : 'button-div'}
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-list-ol"></i>

      </div>


      <div
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'button-div-active' : 'button-div'}
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-list-ul"></i>
      </div>

      <div className="vr"></div>

      <div
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        className={editor.isActive({ textAlign: 'left' }) ? 'button-div-active' : 'button-div'}
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-text-left"></i>
      </div>


      <div
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        className={editor.isActive({ textAlign: 'center' }) ? 'button-div-active' : 'button-div'}
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-text-center"></i>
      </div>

      <div
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        className={editor.isActive({ textAlign: 'right' }) ? 'button-div-active' : 'button-div'}
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-text-right"></i>
      </div>

      <div className="vr"></div>

      <div
        onClick={setLink}
        className={editor.isActive('link') ? 'button-div-active' : 'button-div'}
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-link-45deg"></i>
      </div>


      <div className="vr"></div>

      <div
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'button-div-active' : 'button-div'}
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-quote"></i>
      </div>

      <div
        onClick={() => editor.chain().focus().clearNodes().run()}
        className='button-div'
        style={menuBarStylingObject.buttonDivStyling}
      >
        <i className="bi bi-x-square"></i>
      </div>

    </div>
  )
}

export default TextEditorMenuBar
