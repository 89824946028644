import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { AuthenticationContextProvider} from './contexts/AuthContext'; 
import ManageScheduledCampaigns from './pages/ManageScheduledCampaigns';
import Login from './pages/Login';
import DashboardPage from './pages/DashboardPage';
import CampaignCreation from './pages/CampaignCreation';
import NavigationBar from './components/NavigationBar';
import SentCampaign from './pages/SentCampaign';
import DraftCampaign from './pages/DraftCampaign';
import ProfilePage from './pages/ProfilePage';
import Analytics from './components/Analytics';

const App = () => {
  return (
    
    <Router>
    <AuthenticationContextProvider>
    <Analytics />
    <NavigationBar/>
    <Routes>
      <Route path='/' element={<Login/>}/> 
      <Route path='/create-campaign' element={<CampaignCreation/>}/>
      <Route path='/dashboard' element={<DashboardPage/>} />
      <Route path='/profile' element={<ProfilePage/>} />
      <Route path='/scheduled-campaign/:campaign_id' element={<ManageScheduledCampaigns/>} /> 
      <Route path='/sent-campaign/:campaign_id' element={<SentCampaign/>} />
      <Route path='/draft-campaign/:campaign_id' element={<DraftCampaign/>} />
    </Routes>
    </AuthenticationContextProvider>
    </Router>
  )
}

export default App
