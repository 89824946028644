import React, { useState, useContext } from 'react';

import { AuthenticationContext } from '../contexts/AuthContext';
import ErrorModal from '../errors/ErrorModal';
import SubscriptionPlanTable from './SubscriptionPlanTable';
import LoadingIcon from './LoadingIcon';
import '../styles/CampaignList.css';
import FormSwitch from "./FormSwitch"

import EmailingAPI from '../apis/EmailingAPI';

const StripePayments = ({ userAccountData }) => {

  const { userSession } = useContext(AuthenticationContext);
  const [fetchError, setFetchError] = useState(null);
  const [buttonClickable, setIsButtonClickable] = useState(true);
  const [isSubscriptionMonthly, setIsSubscriptionMonthly] = useState(true);
  const [loading, setLoading] = useState(false);

  //TODO: This isn't completely fixed. It still changes between isSubscriptionMonthly === true/false or "monthly"/"yearly"
  const monthlySubscriptions = {
    basic: {
      name: 'Basic',
      price: 15,
      emailAddresses: '5',
      emailSends: 3000,
      planIcon: <i className="bi bi-flower3"></i>,
      emailContacts: "Unlimited",
      stylingCss: "bg-basic",
      priceId: process.env.REACT_APP_STRIPE_BASIC_PLAN_MONTHLY  // CHANGED
    },
    pro: {
      name: 'Pro',
      price: 27,
      emailAddresses: '10',
      emailSends: 9000,
      planIcon: <i className="bi bi-award"></i>,
      emailContacts: "Unlimited",
      stylingCss: "bg-pro",
      priceId: process.env.REACT_APP_STRIPE_PRO_PLAN_MONTHLY    // CHANGED
    },
    expert: {
      name: 'Expert',
      price: 39,
      emailAddresses: '25',
      emailSends: 15000,
      planIcon: <i className="bi bi-trophy"></i>,
      emailContacts: "Unlimited",
      stylingCss: "bg-expert",
      priceId: process.env.REACT_APP_STRIPE_EXPERT_PLAN_MONTHLY // CHANGED
    }
  };

  const yearlySubscriptions = {
    basic: {
      name: 'Basic',
      price: 12.5,
      emailAddresses: '5',
      emailSends: 3000,
      planIcon: <i className="bi bi-flower3"></i>,
      emailContacts: "Unlimited",
      priceId: process.env.REACT_APP_STRIPE_BASIC_PLAN_YEARLY   // CHANGED
    },
    pro: {
      name: 'Pro',
      price: 22.5,
      emailAddresses: '10',
      emailSends: 9000,
      planIcon: <i className="bi bi-award"></i>,
      emailContacts: "Unlimited",
      priceId: process.env.REACT_APP_STRIPE_PRO_PLAN_YEARLY     // CHANGED
    },
    expert: {
      name: 'Expert',
      price: 32.5,
      emailAddresses: '25',
      emailSends: 15000,
      planIcon: <i className="bi bi-trophy"></i>,
      emailContacts: "Unlimited",
      priceId: process.env.REACT_APP_STRIPE_EXPERT_PLAN_YEARLY  // CHANGED
    }
  };


  const openCheckout = async (stripePriceId) => {
    if (!buttonClickable) {
      return;
    }
    setIsButtonClickable(false);

    try {

      const sendableData = {
        userSession,
        subscriptionPriceId: stripePriceId,
      };
            
      const { data } = await EmailingAPI.post('create-checkout-session/', sendableData);
      if (data && data.url) {
         window.location.href = data.url; 
        
      }
    } catch (error) {
      console.error(error);
      setFetchError('Failed to open Stripe Checkout');
    } finally {
      setIsButtonClickable(true);
    }
  };

  const selectedPlans = isSubscriptionMonthly === true
    ? monthlySubscriptions
    : yearlySubscriptions;

  return (
    <>
      {loading && <LoadingIcon />}

{/*       <div className="d-flex justify-content-center mb-4 mt-4">
        <div className="btn-group" role="group" aria-label="Subscription Period Toggle">
          <button
            type="button"
            className={`btn btn-outline-primary ${subscriptionPeriod === "Monthly" ? "active" : ""}`}
            onClick={() => setSubscriptionPeriod("Monthly")}
          >
            Monthly
          </button>
          <button
            type="button"
            className={`btn btn-outline-primary ${subscriptionPeriod === "Yearly" ? "active" : ""}`}
            onClick={() => setSubscriptionPeriod("Yearly")}
          >
            Yearly
          </button>
        </div>
      </div> */}
        <div className="d-flex justify-content-center mt-4" style={{fontSize: "1.2em"}}>
        <FormSwitch value={isSubscriptionMonthly} setValue={setIsSubscriptionMonthly} displayedTexts={{ firstText: "Monthly", secondText: "Yearly" }} />

        </div>

      <SubscriptionPlanTable
        plans={selectedPlans}
        openCheckout={openCheckout}
        currentSubscription={userAccountData}
      />

      {fetchError && (
        <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError} />
      )}


    </>
  );
};

export default StripePayments;