import React, { useState, useRef } from 'react';
import '../styles/ModalStyles.css'
import InlineError from '../errors/InlineError';
import RichTextEditor from '../components/RichTextEditor';
import FormSwitch from '../components/FormSwitch';
import BasicModal from '../components/BasicModal';

const IndividualEmailEditor = ({ writeText, initialEmailContent, stylingProp, targetColumn }) => {

    const richTextRef = useRef(null);

    const [modalVisible, setModalVisible] = useState(false);
    const [inlineError, setInlineError] = useState(null);
    const [plainText, setPlainText] = useState("");
    const [isTextEditorPlain, setTextEditorPlain] = useState(false);

    const generateInitialContent = (editorRef, providedValue) => {
        if (editorRef.current !== null && typeof editorRef.current.getHTML() === "string") {
            return editorRef.current.getHTML()
        } else {
            return providedValue
        }
    }

    const toggleModal = () => {
        setModalVisible(!modalVisible)
    }

    const handleTextChange = (text, columnKey, errorMethod) => {
        writeText(text, columnKey, errorMethod)
    }

    return (
        <div>
            <button
                title="Edit the email subject line and body."
                className={stylingProp.buttonStyling}
                onClick={() => toggleModal()} > <i className="bi bi-pencil-square"></i>
                {stylingProp.buttonText}
            </button>

            {modalVisible ?
                    <BasicModal title={"Edit Email Content"}>
                        <div className="modal-body">
                            <div className="edit-email-form-group">

                                <FormSwitch value={isTextEditorPlain} setValue={setTextEditorPlain} displayedTexts={{ firstText: "Rich Text", secondText: "Plain Text" }} />
                                {/* <label htmlFor="email-body" className="edit-email-form-label">Email Body</label> */}
                                {isTextEditorPlain ?
                                    <RichTextEditor
                                        textEditorRef={richTextRef}
                                        editorConfiguration={{
                                            showMenuBar: true,
                                            initialContent: generateInitialContent(richTextRef, initialEmailContent.emailBody),
                                            cssStyling: "bulk-email-editor"
                                        }}
                                    />
                                    :
                                    <textarea
                                        id="email-body"
                                        value={plainText}
                                        onChange={(e) => setPlainText(e.target.value)}
                                        className="edit-email-form-textarea"
                                    />
                                }
                            </div>
                            {inlineError && <InlineError errorMessage={inlineError} setErrorMessage={setInlineError} />}
                        </div>
                        <div className="modal-footer">
                            {isTextEditorPlain ?
                                <button type="button" onClick={() => handleTextChange(richTextRef.current.getHTML(), targetColumn, setInlineError)} className="btn btn-primary">Set Text</button>
                                :
                                <button type="button" onClick={() => handleTextChange(plainText, targetColumn, setInlineError)} className="btn btn-primary">Set Text</button>
                            }
                            <button type="button" className="btn btn-secondary" onClick={toggleModal}>Close</button>
                        </div>
                    </BasicModal>
                :
                null
            }
        </div>
    )
}

export default IndividualEmailEditor
