import React from 'react'
import { useState, useEffect, useContext } from 'react';
import EmailingAPI from '../apis/EmailingAPI';
import { AuthenticationContext } from './../contexts/AuthContext';
import ErrorModal from '../errors/ErrorModal';
import LoadingIcon from '../components/LoadingIcon';
import { handleSendEmails } from '../utils/handleSendEmails';
import { validateFollowUpSchedules } from '../utils/validateFollowUpSchedules';
import { validateCampaignConfig } from '../utils/validateCampaignConfig';


//IMPORTANT TODO: IMPOSE USAGE LIMITS ON UPDATING, EITHER HERE OR ON THE FRONTEND.

const UpdateCampaignButton = ({ table, campaignConfiguration }) => {
  const { user, userSession } = useContext(AuthenticationContext);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [buttonClickable, setIsButtonClickable] = useState(true);

  const handleUpdateCampaign = async (e) => {
    if (!buttonClickable ) {      
      return;
    }
    setIsButtonClickable(false)
    e.preventDefault();
    setLoading(true)    
    let apiPath = ""
    if (campaignConfiguration.campaignType === "scheduled-management") {
      apiPath = "update-scheduled-campaign/"
      let configCheck = validateCampaignConfig(campaignConfiguration)
      if (configCheck.validConfig === false) {
        setFetchError(configCheck.error)
        setLoading(false)
        return 
      }
    }



    if (campaignConfiguration.campaignType === "sent-management") {
      apiPath = "update-followup-emails/"
    }
    //TODO: How to handle when the user sets the campaign time to "Send Now"
    if (validateFollowUpSchedules(campaignConfiguration) !== true) {
      setFetchError(validateFollowUpSchedules(campaignConfiguration))
      setLoading(false)
      return
    }

    if (!campaignConfiguration.emailSendTime && campaignConfiguration.campaignType === "scheduled-management") {
      apiPath = "force-launch-scheduled-campaign/"
   
    }

    try {
      const result = await handleSendEmails(campaignConfiguration,table, apiPath, userSession, {
        timeout: 5000, // Set the timeout to 5000ms (5 seconds)
      });
      if (result.status === 200) {
        setLoading(false)
      }
    } catch (error) {
      setFetchError("There was an error updating the campaign.")
      setLoading(false)
    }
    setIsButtonClickable(true)

  }

  return (
    <div>
      <button className="table-bar-button"
        title='Update the followups and other details of the campaign.'
        onClick={(e) => handleUpdateCampaign(e)}>
        <i className="bi bi-cloud-arrow-up"></i>
         Update Campaign 
         </button>
      {fetchError ?
        <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError} />
        :
        null
      }
      {loading ? <LoadingIcon/> : null}
    </div>
  )
}

export default UpdateCampaignButton