import React, { useState, useEffect } from 'react';
import FollowUpColumn from './FollowUpColumn';
import LaunchCampaignButton from './LaunchCampaignButton';
import SelectEmailColumnKey from './SelectEmailColumnKey';
import EmailScheduler from './EmailScheduler';
import EditEmail from './EditEmail';
import SaveCampaignDraftButton from './SaveCampaignDraftButton';
import TimeBetweenSends from './TimeBetweenSends';
import CheckCampaignReplies from './CheckCampaignReplies';
import UpdateCampaignButton from './UpdateCampaignButton';

const TableOptionsBar = ({ table, addColumnToTable, writeTextInAllRows, removeColumnFromTable, campaignConfiguration, setCampaignConfiguration }) => {
  
  return (
    <div  /* style={{ backgroundColor: "#f9fbfd" }}  */ className="mt-2 mb-2 border-bottom" >
      <div className='border-bottom'>
        <input
          className='mb-1 ms-2 campaign-name-input'
          type="text"
          id='campaign-name'
          placeholder={campaignConfiguration.campaignName}
          value={campaignConfiguration.campaignName}
          onChange={(e) => setCampaignConfiguration((prevConfig) => ({
            ...prevConfig,
            campaignName: e.target.value,
          }))} />
      </div>

      { /* ========= SENT CAMPAIGN CONFIG ==============*/
        campaignConfiguration.campaignType === 'sent-management' ?

          <div className="d-flex justify-content-start align-items-center">
            <div>
              <FollowUpColumn
                table={table}
                setCampaignConfiguration={setCampaignConfiguration}
                removeColumnFromTable={removeColumnFromTable}
                writeTextInAllRows={writeTextInAllRows}
                followUpId={'Follow-Up 1'}
                addColumnToTable={addColumnToTable}
                campaignConfiguration={campaignConfiguration}
              />
            </div>
            <div>
              <FollowUpColumn
                table={table}
                setCampaignConfiguration={setCampaignConfiguration}
                removeColumnFromTable={removeColumnFromTable}
                writeTextInAllRows={writeTextInAllRows}
                followUpId={'Follow-Up 2'}
                addColumnToTable={addColumnToTable}
                campaignConfiguration={campaignConfiguration}
              />
            </div>
            <div>
              <FollowUpColumn
                table={table}
                setCampaignConfiguration={setCampaignConfiguration}
                removeColumnFromTable={removeColumnFromTable}
                writeTextInAllRows={writeTextInAllRows}
                followUpId={'Follow-Up 3'}
                addColumnToTable={addColumnToTable}
                campaignConfiguration={campaignConfiguration}
              />
            </div>
            <div>
              <UpdateCampaignButton
                campaignConfiguration={campaignConfiguration}
                table={table}
              />
            </div>
            <div>
              <CheckCampaignReplies
                campaignConfiguration={campaignConfiguration}
                table={table}
              />
            </div>
          </div>


          /* ========= SCHEDULED CAMPAIGN CONFIG ==============*/
          :
          campaignConfiguration.campaignType === 'scheduled-management' ?
            <div className='campaign-options-bar'>

              <div className='campaign-options-item'>
                <SelectEmailColumnKey
                  setCampaignConfiguration={setCampaignConfiguration}
                  table={table}
                  lastChosenColumnKey={campaignConfiguration.emailColumnKey}
                />
              </div>

              <div >
                <EditEmail
                  writeTextInAllRows={writeTextInAllRows}
                  initialEmailContent={ {
                    emailBody: campaignConfiguration["Email Body"],
                    subjectLine: campaignConfiguration["Subject Line"],
                  }}
                  stylingProp={{
                    buttonStyling: "table-bar-button",
                    buttonText: "Edit"
                  }}
                />
              </div>

              <div >
                <EmailScheduler
                  setCampaignConfiguration={setCampaignConfiguration}
                  emailToSchedule={'emailSendTime'}
                  description={"Schedule"}
                  lastChosenTime={campaignConfiguration.emailSendTime ? campaignConfiguration.emailSendTime.localDateFormatted : null}
                />
              </div>
              {/*         <div>
          <TimeBetweenSends
            setCampaignConfiguration={setCampaignConfiguration}
            lastChosenInterval={campaignConfiguration.timeBetweenSends}
          />
        </div> */}
              <div >
                <FollowUpColumn
                  table={table}
                  setCampaignConfiguration={setCampaignConfiguration}
                  removeColumnFromTable={removeColumnFromTable}
                  writeTextInAllRows={writeTextInAllRows}
                  followUpId={'Follow-Up 1'}
                  addColumnToTable={addColumnToTable}
                  campaignConfiguration={campaignConfiguration}
                />
              </div>
              <div >
                <FollowUpColumn
                  table={table}
                  setCampaignConfiguration={setCampaignConfiguration}
                  removeColumnFromTable={removeColumnFromTable}
                  writeTextInAllRows={writeTextInAllRows}
                  followUpId={'Follow-Up 2'}
                  addColumnToTable={addColumnToTable}
                  campaignConfiguration={campaignConfiguration}
                />
              </div>
              <div >
                <FollowUpColumn
                  table={table}
                  setCampaignConfiguration={setCampaignConfiguration}
                  removeColumnFromTable={removeColumnFromTable}
                  writeTextInAllRows={writeTextInAllRows}
                  followUpId={'Follow-Up 3'}
                  addColumnToTable={addColumnToTable}
                  campaignConfiguration={campaignConfiguration}
                />
              </div>
              <div>
                <UpdateCampaignButton
                  campaignConfiguration={campaignConfiguration}
                  table={table}
                />
              </div>
            </div>
            :

            /* ========= CREATE CAMPAIGN CONFIG ==============*/
            <div className='campaign-options-bar'>
              <div>
                <SaveCampaignDraftButton
                  campaignConfiguration={campaignConfiguration}
                  table={table}
                />
              </div>

              <div className='campaign-options-item'>
                <SelectEmailColumnKey
                  setCampaignConfiguration={setCampaignConfiguration}
                  table={table}
                  lastChosenColumnKey={campaignConfiguration.emailColumnKey}
                />
              </div>

              <div >
                <EditEmail
                  writeTextInAllRows={writeTextInAllRows}
                  initialEmailContent={ {
                    emailBody: campaignConfiguration["Email Body"],
                    subjectLine: campaignConfiguration["Subject Line"],
                  }}
                  stylingProp={{
                    buttonStyling: "table-bar-button",
                    buttonText: "Edit"
                  }}
                />
              </div>

              <div >
                <EmailScheduler
                  setCampaignConfiguration={setCampaignConfiguration}
                  emailToSchedule={'emailSendTime'}
                  description={"Schedule"}
                  lastChosenTime={campaignConfiguration.emailSendTime ? campaignConfiguration.emailSendTime.localDateFormatted : null}
                />
              </div>
              {/*           <div>
            <TimeBetweenSends
              setCampaignConfiguration={setCampaignConfiguration}
              lastChosenInterval={campaignConfiguration.timeBetweenSends}
            />
          </div> */}
              <div >
                <FollowUpColumn
                  table={table}
                  setCampaignConfiguration={setCampaignConfiguration}
                  removeColumnFromTable={removeColumnFromTable}
                  writeTextInAllRows={writeTextInAllRows}
                  followUpId={'Follow-Up 1'}
                  addColumnToTable={addColumnToTable}
                  campaignConfiguration={campaignConfiguration}
                />
              </div>
              <div >
                <FollowUpColumn
                  table={table}
                  setCampaignConfiguration={setCampaignConfiguration}
                  removeColumnFromTable={removeColumnFromTable}
                  writeTextInAllRows={writeTextInAllRows}
                  followUpId={'Follow-Up 2'}
                  addColumnToTable={addColumnToTable}
                  campaignConfiguration={campaignConfiguration}
                />
              </div>
              <div>
                <FollowUpColumn
                  table={table}
                  setCampaignConfiguration={setCampaignConfiguration}
                  removeColumnFromTable={removeColumnFromTable}
                  writeTextInAllRows={writeTextInAllRows}
                  followUpId={'Follow-Up 3'}
                  addColumnToTable={addColumnToTable}
                  campaignConfiguration={campaignConfiguration}
                />
              </div>
              <div>
                <LaunchCampaignButton
                  campaignConfiguration={campaignConfiguration}
                  table={table}
                />
              </div>
            </div>
      }
    </div>
  );
}

export default TableOptionsBar
