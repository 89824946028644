import React, { useState } from 'react';

const StaticTextCell = ({ getValue, row, column, table }) => {
  const initialValue = getValue() || "";
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandBtnClicked, setIsExpandBtnClicked] = useState(false);
  const [editableCellStyling, setEditableCellStyling] = useState("table-editable-cell-collapsed")
  
  const [expandButtonStyle, setExpandButtonStyle] = useState({display: 'none'});

  const handleMouseEnter = () => {
    if (!isExpandBtnClicked) setExpandButtonStyle({});
  };

  const handleMouseLeave = () => {
    if (!isExpandBtnClicked) setExpandButtonStyle({display: 'none'});
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded)
    isExpanded ? setEditableCellStyling("table-editable-cell-collapsed") : setEditableCellStyling("table-editable-cell")
    setIsExpandBtnClicked(!isExpandBtnClicked);
    setExpandButtonStyle(isExpandBtnClicked ? {display: 'none'} : {});
  };


  return (
    <div
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      style={{ position: 'relative', height: '100%' }}>

        <div
        dangerouslySetInnerHTML={{ __html: initialValue }}
        className={editableCellStyling}
      />


<div 
      style={{display:"flex", justifyContent:"center", minHeight:"2em", gap:"1em"}}>
      <div style={expandButtonStyle}>
        <button
          className= 'btn btn-outline-primary editable-cell-btn' 
          onClick={() => handleExpandClick()}>
          {isExpanded ? <i className="bi bi-arrows-collapse-vertical"></i> : <i className="bi bi-arrows"></i>}
        </button>
      </div>
      </div>
    </div>
  );
};

export default StaticTextCell;