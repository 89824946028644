import React from 'react'
import '../styles/ModalStyles.css'

const FormSwitch = ({value, setValue, displayedTexts}) => {
    
  return (
    <div className="form-check form-switch cleaned-text-switch">
    <div className="switch-container">
      <input
        className="form-check-input"
        onChange={() => setValue(!value)}
        type="checkbox"
        id="flexSwitchCheckDefault"
      />
      <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">
        {value ? `${displayedTexts.firstText}` : `${displayedTexts.secondText}`}
      </label>
    </div>
  </div>
  )
}

export default FormSwitch
