import React, { useState, useEffect, useContext } from 'react';
import { useLocation} from 'react-router-dom';
import ManageCampaign from '../components/ManageCampaign'
import { supabase } from '../libraries/supabase';
import ErrorModal from '../errors/ErrorModal';
import PreviewMail from '../table_features/PreviewMail';
import '../styles/CampaignManagement.css'


const ManageScheduledCampaigns = () => {
  const location = useLocation();
  const campaignId = location.state.id;
  const [campaignData, setCampaignData] = useState(null);
  const [fetchError, setFetchError] = useState(null)
  const [loading, setLoading] = useState(true);
  const [senderEmailDetails, setSenderEmailDetails] = useState(null);
  const [campaignConfiguration, setCampaignConfiguration] = useState(null)
  
  const scheduledCampaignConfig = {
    headerFields: [],
    headers: {},
    cellRenderers: {
      "Preview Mail": (props) => <PreviewMail {...props} senderAddress={senderEmailDetails} />
    },
    cellContent:{},
    campaignType: 'scheduled-management'
  }


  useEffect(() => {
    const fetchCampaignData = async () => {
      const { data, error } = await supabase
      .from('scheduled_campaigns')
      .select(`
        *,
        sender_email_addresses(*)
      `)
      .eq('campaign_id', campaignId);

        if(error){
          setFetchError("Could not fetch campaign data"); 
        }
        if (data) {
          
          const {
            campaignConfiguration,
            emailContacts,
            tableColumnKeys
          } = data[0].email_to_send_object
          
          //TODO: Not urgent, but this should be done somewhere else.
          campaignConfiguration.campaignType = 'scheduled-management'
          campaignConfiguration.campaignId = campaignId

          const {sender_email_addresses: senderEmailAddress} = data[0]
          setCampaignData({
            data: emailContacts,
            meta: {
              fields: tableColumnKeys
            }
          });
          setCampaignConfiguration(campaignConfiguration)
          setFetchError(null);
          setSenderEmailDetails(senderEmailAddress)
          setLoading(false)
        }
    }
    fetchCampaignData();


  }, []);


  return (
    <>

    {!loading && campaignData ? 
    <ManageCampaign 
    campaignHeaderCellConfig={scheduledCampaignConfig} 
    senderEmailAddress={senderEmailDetails} 
    tableData={campaignData}
    campaignConfig={campaignConfiguration}
    />  
    : 
    null
    }
    {fetchError && <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError}/>}

    </>
  )
}

export default ManageScheduledCampaigns
