
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import {
  useReactTable,
  ColumnResizeMode,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingFn,
  SortingState,
  ColumnDef,
  flexRender,
  ColumnResizeDirection
} from '@tanstack/react-table';
import EditableCell from '../table_features/EditableCell';
import ColumnVisibility from '../table_features/ColumnVisibility';
import TableOptionsBar from '../table_features/TableOptionsBar';
import ErrorModal from '../errors/ErrorModal';
import TablePaginationHandler from '../table_features/TablePaginationHandler';
import { modifyEmailSubjectAndBody } from '../table_features/ModifyEmailSubjectAndBody';
import { columnInsertionOrder } from '../table_features/ColumnInsertionOrder';
import { dateSort } from '../table_features/DateSorting';
import "../styles/CampaignManagement.css"

const ManageCampaign = ({ campaignHeaderCellConfig, senderEmailAddress, tableData, campaignConfig }) => {

  const defaultCampaignConfig = {
    campaignId: null,
    campaignName: "Untitled Campaign",
    "Follow-Up 1 Schedule": null,
    "Follow-Up 2 Schedule": null,
    "Follow-Up 3 Schedule": null,
    emailColumnKey: null,
    emailSendTime: null,
    senderAddress: senderEmailAddress,
    "Subject Line": null,
    "Email Body": null,
    "Follow-Up 1": "",
    "Follow-Up 2": "",
    "Follow-Up 3": "",
    timeBetweenSends: 0,
    followupCount: 0,
    campaignType: campaignHeaderCellConfig.campaignType || null,
    initialSortingState: null
  };

  const [campaignConfiguration, setCampaignConfiguration] = useState(
    campaignConfig || defaultCampaignConfig
  );
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [fetchError, setFetchError] = useState(null);

  const [sorting, setSorting] = useState(campaignConfiguration.initialSortingState);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const [columnSizes, setColumnSizes] = useState({});

  const createTableData = (results) => {

    //this creates a copy of results.data, to which we append the campaignHeaderCellConfig to create the table
    const newData = [];
    for (let index = 0; index < results.data.length; index++) {

      const newItem = {
        ...results.data[index],
        ...campaignHeaderCellConfig.cellContent
      };
      newData.push(newItem);
    }

    const headers = [...results.meta.fields, ...campaignHeaderCellConfig.headerFields];

    const columnDefs = headers.map((field) => {
      // Create the base object structure
      let columnDef = {}
      if (campaignHeaderCellConfig.headers.hasOwnProperty(field)) {
        columnDef = campaignHeaderCellConfig.headers[field]

      } else {
        columnDef = {
          accessorKey: field,
          header: () => field,
          cell: campaignHeaderCellConfig.cellRenderers[field] || ((props) => <EditableCell {...props} />),
          enableSorting: false,
        };
      }
      return columnDef;
    });

    setColumns(columnDefs);
    setData(newData);
  }

  const addColumnToTable = (columnProps) => {
    const addedCols = columnInsertionOrder(columns, columnProps)
    setColumns(addedCols)
  }

  const removeColumnFromTable = (columnKey) => {

    //why this script exists: filtered columns removes the column from the table.
    //however, the information from within the column was still stored in the table
    //thus, cleanedData is used to remove the data from the table
    const cleanedData = data.map(({ [columnKey]: _, ...rest }) => rest);
    const filteredColumns = columns.filter(column => column.accessorKey !== columnKey)
    setColumns(filteredColumns)
    setData(cleanedData)
  }

  useEffect(() => {
    createTableData(tableData)

  }, [tableData])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting: sorting,
      pagination: pagination,
    },
    /*     sortingFns: {
          dateSort
        }, */
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    //onEnd seems to have a much better performance impact 
    columnResizeMode: 'onChange',
    columnResizeDirection: "ltr",
    meta: {
      updateData: (rowIndex, columnId, value) => setData(
        prev => prev.map(
          (row, index) =>
            index === rowIndex ? {
              ...prev[rowIndex],
              [columnId]: value
            } : row
        )
      )
    }
  });


  const writeTextInAllRows = (text, columnKey, errorMethod) => {
    const updatedData = data.map(row => {
      let replacedEmailBody = modifyEmailSubjectAndBody(row, text, errorMethod);
      setCampaignConfiguration((prevConfig) => ({
        ...prevConfig,
        [columnKey]: text
      }))
      return {
        ...row,
        [columnKey]: replacedEmailBody
      };

    });
    setData(updatedData);
    //onsole.log(updatedData);
    
  };

  const recalculateColumnWidth = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      colSizes[`--header-${header.id.replaceAll(" ", "-")}-size`] = header.getSize();
      colSizes[`--col-${header.column.id.replaceAll(" ", "-")}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  return (
    <div className='manage-campaign-component'>

      {tableData ?
        <TableOptionsBar
          campaignConfiguration={campaignConfiguration}
          setCampaignConfiguration={setCampaignConfiguration}
          table={table}
          removeColumnFromTable={removeColumnFromTable}
          writeTextInAllRows={writeTextInAllRows}
          addColumnToTable={addColumnToTable}
        /> : null}
      {tableData ? <ColumnVisibility table={table} /> : null}

      <div className='campaign-table-container'  >
        <table id="create_campaign_table"
          className="table table-bordered"
          style={{
            minWidth: 'max-content',
            tableLayout: 'fixed',
            width: "100%",

            ...recalculateColumnWidth
          }}
          width={table.getTotalSize()} >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className='manage-campaign-th'
                    style={{
                      width: `calc(var(--header-${header?.id.replaceAll(" ", "-")}-size) * 1px)`,
                      position: 'relative',
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                    <div
                      onDoubleClick={() => setColumnSizes((prev) => ({ ...prev, [header.id]: 0 }))}
                      onMouseDown={header.getResizeHandler()}

                      className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''
                        }`}
                      style={{
                      }}
                    />
                    {header.column.getCanSort() && <i className="bi bi-arrow-down-up ms-2"></i>}
                    {{ asc: <i className="bi bi-sort-alpha-down ms-2"></i>, desc: <i className="bi bi-sort-alpha-up ms-2"></i> }[header.column.getIsSorted() ?? null]}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {table.getState().columnSizingInfo.isResizingColumn ? (
            <MemoizedTableBody table={table} />
          ) : (
            <TableBody table={table} />
          )}
        </table>
      </div>
      <TablePaginationHandler table={table} />
      {fetchError && <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError} />}
    </div>
  );

};

function TableBody({ table }) {
  return (
    <tbody>
      {table.getRowModel().rows.map((row) => (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className='manage-campaign-td' style={{ width: `calc(var(--col-${cell.column.id.replaceAll(" ", "-")}-size) * 1px)`, }}   >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>

  )
}

const MemoizedTableBody = React.memo(
  TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
)

export default ManageCampaign;
