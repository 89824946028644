import React from 'react';
import '../styles/ModalStyles.css'

const BasicModal = ({ title, children  }) => {
  return (
    <>
    <div className="modal-backdrop-custom"></div>
    <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" style={{
                    overflow: "hidden",
                    whiteSpace: "normal",  
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    maxWidth: "100%"
                  }}>{title}</h5>
          </div>
        {children}
        </div>
      </div>
    </div>
  </>
  );
};

export default BasicModal;