import React, { useState, useEffect} from 'react';
import IndividualEmailEditor from './IndividualEmailEditor';

const EditableCell = ({ getValue, row, column, table }) => {
  const initialValue = getValue() || "";
  const [value, setValue] = useState(initialValue);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandBtnClicked, setIsExpandBtnClicked] = useState(false);
  const [editableCellStyling, setEditableCellStyling] = useState("table-editable-cell-collapsed")
  const [expandButtonStyle, setExpandButtonStyle] = useState({display: 'none'})

  
  const handleMouseEnter = () => {
    if (!isExpandBtnClicked) setExpandButtonStyle({});
  };

  const handleMouseLeave = () => {
    if (!isExpandBtnClicked) setExpandButtonStyle({display: 'none'});
  };


  const handleExpandClick = () => {
    
    setIsExpanded(!isExpanded)
    isExpanded ? setEditableCellStyling("table-editable-cell-collapsed") : setEditableCellStyling("table-editable-cell")
    setIsExpandBtnClicked(!isExpandBtnClicked);
    setExpandButtonStyle(isExpandBtnClicked ? {display: 'none'} : {});
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const updateRowContent = (text, columnKey, errorMethod) => {
      table.options.meta?.updateData(
        row.index,
        columnKey,
        text
      )
  }

  const handleInputChange = (e) => {
    let htmlContent = e.target.innerHTML;
    //TODO You should have an html purifier here   
    table.options.meta?.updateData(
      row.index,
      column.id,
      htmlContent
    );
  };

  return (
    <div
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      >

      <div
        dangerouslySetInnerHTML={{ __html: value }}
        contentEditable="true"
        className={editableCellStyling}
        onBlur={handleInputChange}
      />

      <div 
      style={{display:"flex", justifyContent:"center", minHeight:"2em", gap:"1em"}}>
      <div style={expandButtonStyle}>
        <button
          className='btn btn-outline-primary editable-cell-btn' 
          onClick={() => handleExpandClick()}>
          {isExpanded ? <i className="bi bi-arrows-collapse-vertical"></i> : <i className="bi bi-arrows"></i>}
        </button>
      </div>
      {isExpanded ? 
      <IndividualEmailEditor
      writeText={updateRowContent}
      initialEmailContent={ {
        emailBody: row.original[column.id],
      }}
      targetColumn={column.id}
      stylingProp={{
        buttonStyling: "btn btn-outline-primary editable-cell-btn",
        buttonText: null
      }}
      />
      :
       null
      }
      </div>
    </div>
  );
};

export default EditableCell;